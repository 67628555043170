import React, { useState, useEffect, useCallback } from 'react';
import {
    Container,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    IconButton,
    CircularProgress,
    Paper,
    Snackbar,
    Alert,
    TablePagination,
    TableSortLabel,
    TextField,
    Grid,
} from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon, Description as DescriptionIcon } from '@mui/icons-material';
import axios from 'axios';
import { useAuth } from './auth';
import { useNavigate } from 'react-router-dom';

const CustomPaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div style={{ flexShrink: 0, marginLeft: '2.5em' }}>
            <Button onClick={handleFirstPageButtonClick} disabled={page === 0}>First</Button>
            <Button onClick={handleBackButtonClick} disabled={page === 0}>Back</Button>
            <Button onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1}>Next</Button>
            <Button onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1}>Last</Button>
        </div>
    );
};

const Questions = () => {
    const { token } = useAuth();
    const [questions, setQuestions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5); 
    const [orderBy, setOrderBy] = useState(''); 
    const [orderDirection, setOrderDirection] = useState('asc');
    
    // Filter states
    const [filterText, setFilterText] = useState('');
    const [filterType, setFilterType] = useState('');
    const [filterExpiration, setFilterExpiration] = useState('');
    
    const apiUrl = process.env.REACT_APP_BASE_USER_SERVER;
    const navigate = useNavigate();

    const fetchQuestions = useCallback(async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${apiUrl}/api/questions`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setQuestions(response.data);
        } catch (error) {
            console.error('Error fetching questions', error);
        } finally {
            setLoading(false);
        }
    }, [token]);

    useEffect(() => {
        fetchQuestions();
    }, [fetchQuestions]);

    const handleDelete = async (id) => {
        try {
            await axios.delete(`${apiUrl}/api/questions/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setSnackbarMessage('Question deleted successfully');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
            await fetchQuestions();
        } catch (error) {
            setSnackbarMessage('Error deleting question');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            console.error('Error deleting question', error);
        }
    };

    const formatDateTime = (dateTime) => {
        if (!dateTime) return '';
        const date = new Date(dateTime);
        const formattedDate = date.toISOString().split('T')[0];
        const formattedTime = date.toTimeString().split(' ')[0];
        return `${formattedDate} ${formattedTime}`;
    };

    const isExpired = (expiresAt) => {
        const now = new Date();
        const expirationDate = new Date(expiresAt);
        return expirationDate.getTime() < now.getTime();
    };

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSortRequest = (property) => {
        const isAsc = orderBy === property && orderDirection === 'asc';
        setOrderDirection(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const sortedQuestions = () => {
        return questions
            .filter((question) => {
                return (
                    (!filterText || question.text.toLowerCase().includes(filterText.toLowerCase())) &&
                    (!filterType || question.questionType.toLowerCase().includes(filterType.toLowerCase())) &&
                    (!filterExpiration || formatDateTime(question.expiresAt).includes(filterExpiration))
                );
            })
            .sort((a, b) => {
                // Prioritize non-expired questions
                const aExpired = isExpired(a.expiresAt);
                const bExpired = isExpired(b.expiresAt);

                if (aExpired !== bExpired) {
                    return aExpired ? 1 : -1; // Non-expired comes first
                }

                // If both are either expired or non-expired, apply sorting by other criteria
                if (orderBy === 'text') {
                    return (a.text < b.text ? -1 : 1) * (orderDirection === 'asc' ? 1 : -1);
                }
                if (orderBy === 'questionType') {
                    return (a.questionType < b.questionType ? -1 : 1) * (orderDirection === 'asc' ? 1 : -1);
                }
                if (orderBy === 'expiresAt') {
                    return (new Date(a.expiresAt) - new Date(b.expiresAt)) * (orderDirection === 'asc' ? 1 : -1);
                }
                if (orderBy === 'scheduledPublishAt') {
                    return (new Date(a.scheduledPublishAt) - new Date(b.scheduledPublishAt)) * (orderDirection === 'asc' ? 1 : -1);
                }
                return 0;
            });
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <Container style={{ marginTop: '50px', marginBottom: '50px', display: 'flex', justifyContent: 'center' }}>
            <Paper elevation={0} style={{ padding: '20px', width: '100%', maxWidth: '1400px', borderRadius:'30'}}>
                <Button variant="outlined" color="primary" onClick={() => navigate('/questions/new')} style={{ marginBottom: '20px' }}>
                    Add Question
                </Button>

                {/* Filter inputs */}
                <Grid container spacing={2} style={{ marginBottom: '20px' }}>
                    <Grid item xs={4}>
                        <TextField
                            label="Filter by Text"
                            variant="outlined"
                            fullWidth
                            value={filterText}
                            onChange={(e) => setFilterText(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            label="Filter by Type"
                            variant="outlined"
                            fullWidth
                            value={filterType}
                            onChange={(e) => setFilterType(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            label="Filter by Expiration Date"
                            variant="outlined"
                            fullWidth
                            value={filterExpiration}
                            onChange={(e) => setFilterExpiration(e.target.value)}
                        />
                    </Grid>
                </Grid>

                {loading ? (
                    <CircularProgress />
                ) : (
                    <TableContainer component={Paper} sx={{ width: '100%', overflowX: 'auto' }}>
                        <Table>
                            <TableHead>
                                <TableRow style={{ backgroundColor: '#2196f3' }}>
                                    <TableCell style={{ fontWeight: 'bold', fontSize: '16px', padding: '10px', color: 'white' }}>
                                        <TableSortLabel
                                            active={orderBy === 'text'}
                                            direction={orderBy === 'text' ? orderDirection : 'asc'}
                                            onClick={() => handleSortRequest('text')}
                                        >
                                            Text
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell style={{ fontWeight: 'bold', fontSize: '16px', padding: '10px', color: 'white' }}>
                                        <TableSortLabel
                                            active={orderBy === 'questionType'}
                                            direction={orderBy === 'questionType' ? orderDirection : 'asc'}
                                            onClick={() => handleSortRequest('questionType')}
                                        >
                                            Type
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            fontWeight: 'bold',
                                            fontSize: '16px',
                                            padding: '10px',
                                            color: orderBy === 'expiresAt' ? 'red' : 'white'
                                        }}
                                    >
                                        <TableSortLabel
                                            active={orderBy === 'expiresAt'}
                                            direction={orderBy === 'expiresAt' ? orderDirection : 'asc'}
                                            onClick={() => handleSortRequest('expiresAt')}
                                        >
                                            Expiration Date
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell style={{ fontWeight: 'bold', fontSize: '16px', padding: '10px', color: 'white' }}>
                                        Scheduled Publish Date
                                    </TableCell>
                                    <TableCell style={{ fontWeight: 'bold', fontSize: '16px', padding: '10px', color: 'white' }}>International</TableCell>
                                    <TableCell style={{ fontWeight: 'bold', fontSize: '16px', padding: '10px', color: 'white' }}>Published</TableCell>
                                    <TableCell style={{ fontWeight: 'bold', fontSize: '16px', padding: '10px', color: 'white' }}>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortedQuestions().slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((question) => (
                                    <TableRow key={question.id}>
                                        <TableCell>{question.text}</TableCell>
                                        <TableCell>{question.questionType}</TableCell>
                                        <TableCell style={{ color: isExpired(question.expiresAt) ? 'red' : 'green' }}>
                                            {formatDateTime(question.expiresAt)}
                                        </TableCell>
                                        <TableCell>{formatDateTime(question.scheduledPublishAt)}</TableCell>
                                        <TableCell>{question.international ? 'Yes' : 'No'}</TableCell>
                                        <TableCell>{question.published ? 'Yes' : 'No'}</TableCell>
                                        <TableCell>
                                            <IconButton onClick={() => navigate(`/questions/edit/${question.id}`)}>
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton onClick={() => handleDelete(question.id)}>
                                                <DeleteIcon />
                                            </IconButton>
                                            <IconButton onClick={() => navigate(`/questions/statistics/${question.id}`)}>
                                                <DescriptionIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={questions.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handlePageChange}
                            onRowsPerPageChange={handleRowsPerPageChange}
                            ActionsComponent={CustomPaginationActions}
                        />
                    </TableContainer>
                )}
                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                    <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Paper>
        </Container>
    );
};

export default Questions;
