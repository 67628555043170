import React, { useState, useEffect, useCallback } from 'react';
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
  CircularProgress,
  Paper,
  Snackbar,
  Alert,
  TablePagination,
  TableSortLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tabs,
  Tab,
  TextField,
  Grid,
} from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import axios from 'axios';
import { useAuth } from './auth';

const CustomPaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div style={{ flexShrink: 0, marginLeft: '2.5em' }}>
      <Button onClick={handleFirstPageButtonClick} disabled={page === 0}>First</Button>
      <Button onClick={handleBackButtonClick} disabled={page === 0}>Back</Button>
      <Button onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1}>Next</Button>
      <Button onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1}>Last</Button>
    </div>
  );
};

const Announcements = () => {
  const apiUrl = process.env.REACT_APP_BASE_USER_SERVER;
  const { token } = useAuth();

  const [announcements, setAnnouncements] = useState([]);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [orderBy, setOrderBy] = useState('');
  const [orderDirection, setOrderDirection] = useState('asc');
  const [open, setOpen] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState('en');
  const [currentAnnouncement, setCurrentAnnouncement] = useState({
    id: '',
    translations: {
      en: { title: '', message: '' },
      fr: { title: '', message: '' },
      de: { title: '', message: '' },
      it: { title: '', message: '' },
    },
  });
  const [imageFile, setImageFile] = useState(null);
  const [videoUrl, setVideoUrl] = useState('');
  const [mediaType, setMediaType] = useState('none'); // Media type control

  // States for filters
  const [filterTitle, setFilterTitle] = useState('');
  const [filterCreatedBy, setFilterCreatedBy] = useState('');
  const [filterDate, setFilterDate] = useState('');

  // Fetch announcements
  const fetchAnnouncements = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/api/announcements/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setAnnouncements(response.data);
    } catch (error) {
      console.error('Error fetching announcements', error);
    } finally {
      setLoading(false);
    }
  }, [token, apiUrl]);

  useEffect(() => {
    fetchAnnouncements();
  }, [fetchAnnouncements]);

  const handleOpen = (announcement = null) => {
    if (announcement) {
      const translations = {
        en: { title: '', message: '' },
        fr: { title: '', message: '' },
        de: { title: '', message: '' },
        it: { title: '', message: '' },
      };

      announcement.translations.forEach((t) => {
        translations[t.language] = {
          title: t.title,
          message: t.message,
        };
      });

      setCurrentAnnouncement({
        id: announcement.id,
        translations,
      });
      setVideoUrl(announcement.videoUrl || '');
      setMediaType(announcement.videoUrl ? 'url' : announcement.imageUrl ? 'image' : 'none');
    } else {
      setCurrentAnnouncement({
        id: '',
        translations: {
          en: { title: '', message: '' },
          fr: { title: '', message: '' },
          de: { title: '', message: '' },
          it: { title: '', message: '' },
        },
      });
      setVideoUrl('');
      setMediaType('none');
    }
    setImageFile(null);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${apiUrl}/api/announcements/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSnackbarMessage('Announcement deleted successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      fetchAnnouncements();
    } catch (error) {
      setSnackbarMessage('Error deleting announcement');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      console.error('Error deleting announcement', error);
    }
  };

  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && orderDirection === 'asc';
    setOrderDirection(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedAnnouncements = () => {
    return announcements
      .filter((announcement) => {
        const englishTranslation = announcement.translations.find((t) => t.language === 'en') || {};
        return (
          (!filterTitle || englishTranslation.title.toLowerCase().includes(filterTitle.toLowerCase())) &&
          (!filterCreatedBy || announcement.createdBy.toLowerCase().includes(filterCreatedBy.toLowerCase())) &&
          (!filterDate || new Date(announcement.createdAt).toISOString().split('T')[0] === filterDate)
        );
      })
      .sort((a, b) => {
        if (orderBy === 'title') {
          return (a.title < b.title ? -1 : 1) * (orderDirection === 'asc' ? 1 : -1);
        }
        if (orderBy === 'createdBy') {
          return (a.createdBy < b.createdBy ? -1 : 1) * (orderDirection === 'asc' ? 1 : -1);
        }
        if (orderBy === 'createdAt') {
          return (new Date(a.createdAt) - new Date(b.createdAt)) * (orderDirection === 'asc' ? 1 : -1);
        }
        return 0;
      });
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSubmit = async () => {
    const formData = new FormData();

    const announcementData = {
      title: currentAnnouncement.translations.en.title,
      message: currentAnnouncement.translations.en.message,
      videoUrl: mediaType === 'url' ? videoUrl : null, // Only submit videoUrl if it's a URL
    };

    formData.append('announcement', JSON.stringify(announcementData));

    formData.append('englishTranslation', new Blob([JSON.stringify({
      title: currentAnnouncement.translations.en.title,
      message: currentAnnouncement.translations.en.message,
      language: 'en'
    })], { type: 'application/json' }));

    formData.append('frenchTranslation', new Blob([JSON.stringify({
      title: currentAnnouncement.translations.fr.title,
      message: currentAnnouncement.translations.fr.message,
      language: 'fr'
    })], { type: 'application/json' }));

    formData.append('italianTranslation', new Blob([JSON.stringify({
      title: currentAnnouncement.translations.it.title,
      message: currentAnnouncement.translations.it.message,
      language: 'it'
    })], { type: 'application/json' }));

    formData.append('germanTranslation', new Blob([JSON.stringify({
      title: currentAnnouncement.translations.de.title,
      message: currentAnnouncement.translations.de.message,
      language: 'de'
    })], { type: 'application/json' }));

    if (imageFile && mediaType === 'image') {
      formData.append('imageFile', imageFile); // Submit image if media type is image
    }

    try {
      if (currentAnnouncement.id) {
        // Update announcement
        await axios.put(`${apiUrl}/api/announcements/${currentAnnouncement.id}`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        });
      } else {
        // Create a new announcement
        await axios.post(`${apiUrl}/api/announcements/create`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        });
      }
      fetchAnnouncements();
      handleClose();
    } catch (error) {
      console.error('Error saving announcement', error);
    }
  };

  return (
    <Container style={{ marginTop: '50px', marginBottom: '50px', display: 'flex', justifyContent: 'center' }}>
      <Paper elevation={0} style={{ padding: '20px', width: '100%', maxWidth: '1400px', borderRadius: '30px' }}>
        <Button variant="outlined" color="primary" onClick={() => handleOpen()} style={{ marginBottom: '20px' }}>
          Add Announcement
        </Button>

        {/* Filter Section */}
        <Grid container spacing={2} style={{ marginBottom: '20px' }}>
          <Grid item xs={4}>
            <TextField
              label="Filter by Title"
              variant="outlined"
              fullWidth
              value={filterTitle}
              onChange={(e) => setFilterTitle(e.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Filter by Created By"
              variant="outlined"
              fullWidth
              value={filterCreatedBy}
              onChange={(e) => setFilterCreatedBy(e.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Filter by Date (YYYY-MM-DD)"
              variant="outlined"
              fullWidth
              value={filterDate}
              onChange={(e) => setFilterDate(e.target.value)}
            />
          </Grid>
        </Grid>

        {loading ? (
          <CircularProgress />
        ) : (
          <TableContainer component={Paper} sx={{ width: '100%', overflowX: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow style={{ backgroundColor: '#2196f3' }}>
                  <TableCell style={{ fontWeight: 'bold', fontSize: '16px', padding: '10px', color: 'white' }}>
                    <TableSortLabel
                      active={orderBy === 'title'}
                      direction={orderBy === 'title' ? orderDirection : 'asc'}
                      onClick={() => handleSortRequest('title')}
                    >
                      Title
                    </TableSortLabel>
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold', fontSize: '16px', padding: '10px', color: 'white' }}>
                    <TableSortLabel
                      active={orderBy === 'createdBy'}
                      direction={orderBy === 'createdBy' ? orderDirection : 'asc'}
                      onClick={() => handleSortRequest('createdBy')}
                    >
                      Created By
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: 'bold',
                      fontSize: '16px',
                      padding: '10px',
                      color: orderBy === 'createdAt' ? 'red' : 'white',
                    }}
                  >
                    <TableSortLabel
                      active={orderBy === 'createdAt'}
                      direction={orderBy === 'createdAt' ? orderDirection : 'asc'}
                      onClick={() => handleSortRequest('createdAt')}
                    >
                      Created At
                    </TableSortLabel>
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold', fontSize: '16px', padding: '10px', color: 'white' }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedAnnouncements()
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((announcement) => {
                    const englishTranslation = announcement.translations.find((t) => t.language === 'en') || {};
                    return (
                      <TableRow key={announcement.id}>
                        <TableCell>{englishTranslation.title}</TableCell>
                        <TableCell>{announcement.createdBy}</TableCell>
                        <TableCell>{new Date(announcement.createdAt).toLocaleString()}</TableCell>
                        <TableCell>
                          <IconButton onClick={() => handleOpen(announcement)}>
                            <EditIcon />
                          </IconButton>
                          <IconButton onClick={() => handleDelete(announcement.id)}>
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={announcements.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              ActionsComponent={CustomPaginationActions}
            />
          </TableContainer>
        )}
        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>

        <Dialog
          open={open}
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              handleClose();
            }
          }}
        >
          <DialogTitle>{currentAnnouncement.id ? 'Edit Announcement' : 'Add Announcement'}</DialogTitle>
          <DialogContent>
            <Tabs
              value={currentLanguage}
              onChange={(event, newValue) => setCurrentLanguage(newValue)}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
            >
              <Tab label="English" value="en" />
              <Tab label="Français" value="fr" />
              <Tab label="Deutsch" value="de" />
              <Tab label="Italiano" value="it" />
            </Tabs>
            <TextField
              margin="dense"
              label="Title"
              type="text"
              fullWidth
              name="title"
              value={currentAnnouncement.translations[currentLanguage].title}
              onChange={(e) =>
                setCurrentAnnouncement({
                  ...currentAnnouncement,
                  translations: {
                    ...currentAnnouncement.translations,
                    [currentLanguage]: {
                      ...currentAnnouncement.translations[currentLanguage],
                      title: e.target.value,
                    },
                  },
                })
              }
            />
            <TextField
              margin="dense"
              label="Message"
              type="text"
              fullWidth
              name="message"
              value={currentAnnouncement.translations[currentLanguage].message}
              onChange={(e) =>
                setCurrentAnnouncement({
                  ...currentAnnouncement,
                  translations: {
                    ...currentAnnouncement.translations,
                    [currentLanguage]: {
                      ...currentAnnouncement.translations[currentLanguage],
                      message: e.target.value,
                    },
                  },
                })
              }
              multiline
              rows={4}
            />

            {/* Toggle for media type */}
            <div style={{ marginTop: '10px', marginBottom: '10px' }}>
              <Button
                variant={mediaType === 'image' ? 'contained' : 'outlined'}
                onClick={() => setMediaType('image')}
              >
                Image
              </Button>
              <Button
                variant={mediaType === 'url' ? 'contained' : 'outlined'}
                onClick={() => setMediaType('url')}
                style={{ marginLeft: '10px' }}
              >
                Video URL
              </Button>
            </div>

            {/* Display fields based on selected media type */}
            {mediaType === 'image' && (
              <Button
                variant="outlined"
                component="label"
                fullWidth
                style={{ marginTop: '10px', marginBottom: '10px' }}
              >
                Upload Image
                <input type="file" hidden onChange={(e) => setImageFile(e.target.files[0])} />
              </Button>
            )}
            {imageFile && (
              <div style={{ textAlign: 'center', marginTop: '10px' }}>
                <img
                  src={URL.createObjectURL(imageFile)}
                  alt="Image Preview"
                  style={{ maxWidth: '100%', maxHeight: '150px' }}
                />
              </div>
            )}

            {mediaType === 'url' && (
              <TextField
                margin="dense"
                label="Video URL (YouTube/Vimeo)"
                type="url"
                fullWidth
                name="videoUrl"
                value={videoUrl}
                onChange={(e) => setVideoUrl(e.target.value)}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSubmit} color="primary">
              {currentAnnouncement.id ? 'Update' : 'Add'}
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </Container>
  );
};

export default Announcements;
