import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './Home';
import Questions from './Questions';
import QuestionForm from './QuestionForm';
import Users from './Users';
import Login from './Login';
import Navbar from './Navbar';
import UserDetails from './UserDetails'; // Import UserDetails component
import PrivateRoute from './PrivateRoute';
import ForgotPassword from './ForgotPassword';
import PayoutOperations from './PayoutOperations'; 
import StatisticsReport from './StatisticsReport';
import Deals from './Deals'; // Import the Deals component
import Announcements from './Announcements'; // Import the Announcements component
import { Box } from '@mui/material';

const drawerWidth = 240; // Make sure this is consistent with the width of your sidebar

const App = () => {
  return (
    <div style={{ display: 'flex', height: '100vh', width: '100vw', overflow: 'hidden',  backgroundColor: '#EDF2F7' }}>
      {/* Sidebar Navbar */}
      <Navbar />

      {/* Main Content for Routes */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          marginLeft: `${drawerWidth}px`, // Ensures main content starts after the sidebar
          width: `calc(100% - ${drawerWidth}px)`, // Ensure the main content takes up the remaining space
          height: '100%', // Full height to prevent gaps
          padding: '20px', // Ensure there's padding around the content
          overflowY: 'auto', // Scroll if content overflows
          boxSizing: 'border-box', // Ensure padding is included in the width and height
        }}
      >
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/reset-password" element={<ForgotPassword />} />
          <Route path="/" element={<PrivateRoute><Home /></PrivateRoute>} />
          <Route path="/questions" element={<PrivateRoute><Questions /></PrivateRoute>} />
          <Route path="/questions/new" element={<PrivateRoute><QuestionForm /></PrivateRoute>} />
          <Route path="/questions/edit/:id" element={<PrivateRoute><QuestionForm /></PrivateRoute>} />
          <Route path="/questions/statistics/:id" element={<PrivateRoute><StatisticsReport /></PrivateRoute>} />
          <Route path="/payouts" element={<PrivateRoute><PayoutOperations /></PrivateRoute>} />
          <Route path="/users" element={<PrivateRoute><Users /></PrivateRoute>} />
          <Route path="/user-details/:userId" element={<PrivateRoute><UserDetails /></PrivateRoute>} />
          <Route path="/announcements" element={<PrivateRoute><Announcements /></PrivateRoute>} />
          <Route path="/deals" element={<PrivateRoute><Deals /></PrivateRoute>} /> 
        </Routes>
      </Box>
    </div>
  );
};

export default App;
