import React, { useState, useEffect, useCallback } from 'react';
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
  CircularProgress,
  Paper,
  Snackbar,
  Alert,
  TablePagination,
  TableSortLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tabs,
  Tab,
  TextField,
  Grid, // Import Grid to organize input fields
} from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import axios from 'axios';
import { useAuth } from './auth';

const CustomPaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div style={{ flexShrink: 0, marginLeft: '2.5em' }}>
      <Button onClick={handleFirstPageButtonClick} disabled={page === 0}>
        First
      </Button>
      <Button onClick={handleBackButtonClick} disabled={page === 0}>
        Back
      </Button>
      <Button onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1}>
        Next
      </Button>
      <Button onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1}>
        Last
      </Button>
    </div>
  );
};

const Deals = () => {
  const apiUrl = process.env.REACT_APP_BASE_USER_SERVER;
  const { token, userEmail } = useAuth();

  const [deals, setDeals] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [secondImageFile, setSecondImageFile] = useState(null);
  const [currentLanguage, setCurrentLanguage] = useState('en');
  const [currentDeal, setCurrentDeal] = useState({
    id: '',
    link: '',
    translations: {
      en: { title: '', description: '', readyText: '' },
      fr: { title: '', description: '', readyText: '' },
      de: { title: '', description: '', readyText: '' },
      it: { title: '', description: '', readyText: '' },
    },
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [orderBy, setOrderBy] = useState('');
  const [orderDirection, setOrderDirection] = useState('asc');

  // États pour les filtres
  const [filterTitle, setFilterTitle] = useState('');
  const [filterDescription, setFilterDescription] = useState('');
  const [filterLink, setFilterLink] = useState('');

  const fetchDeals = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/api/deals`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setDeals(response.data);
    } catch (error) {
      console.error('Error fetching deals', error);
    } finally {
      setLoading(false);
    }
  }, [token, apiUrl]);

  useEffect(() => {
    fetchDeals();
  }, [fetchDeals]);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${apiUrl}/api/deals/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSnackbarMessage('Deal deleted successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      fetchDeals();
    } catch (error) {
      setSnackbarMessage('Error deleting deal');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      console.error('Error deleting deal', error);
    }
  };

  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && orderDirection === 'asc';
    setOrderDirection(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedDeals = () => {
    const filteredDeals = deals.filter((deal) => {
      const englishTranslation = deal.translations.find((t) => t.language === 'en') || {};
      const matchesTitle = englishTranslation.title
        .toLowerCase()
        .includes(filterTitle.toLowerCase());
      const matchesDescription = englishTranslation.description
        .toLowerCase()
        .includes(filterDescription.toLowerCase());
      const matchesLink = deal.link.toLowerCase().includes(filterLink.toLowerCase());

      return matchesTitle && matchesDescription && matchesLink;
    });

    return filteredDeals.sort((a, b) => {
      if (orderBy === 'title') {
        return (a.title < b.title ? -1 : 1) * (orderDirection === 'asc' ? 1 : -1);
      }
      if (orderBy === 'link') {
        return (a.link < b.link ? -1 : 1) * (orderDirection === 'asc' ? 1 : -1);
      }
      return 0;
    });
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleOpen = (deal = null) => {
    if (deal) {
      const translations = {
        en: { title: '', description: '', readyText: '' },
        fr: { title: '', description: '', readyText: '' },
        de: { title: '', description: '', readyText: '' },
        it: { title: '', description: '', readyText: '' },
      };

      deal.translations.forEach((t) => {
        translations[t.language] = {
          title: t.title,
          description: t.description,
          readyText: t.readyText,
        };
      });

      setCurrentDeal({
        id: deal.id,
        link: deal.link,
        translations,
      });
    } else {
      setCurrentDeal({
        id: '',
        link: '',
        translations: {
          en: { title: '', description: '', readyText: '' },
          fr: { title: '', description: '', readyText: '' },
          de: { title: '', description: '', readyText: '' },
          it: { title: '', description: '', readyText: '' },
        },
      });
    }
    setImageFile(null);
    setSecondImageFile(null);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLanguageChange = (event, newValue) => {
    setCurrentLanguage(newValue);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentDeal((prevDeal) => ({
      ...prevDeal,
      translations: {
        ...prevDeal.translations,
        [currentLanguage]: {
          ...prevDeal.translations[currentLanguage],
          [name]: value,
        },
      },
    }));
  };

  const handleFileChange = (e) => {
    setImageFile(e.target.files[0]);
  };

  const handleSecondFileChange = (e) => {
    setSecondImageFile(e.target.files[0]);
  };

  const handleSubmit = async () => {
    const formData = new FormData();

    const dealData = {
      link: currentDeal.link,
      title: currentDeal.translations.en.title,
      description: currentDeal.translations.en.description,
      readyText: currentDeal.translations.en.readyText,
    };

    formData.append('deal', JSON.stringify(dealData));

    formData.append(
      'englishTranslation',
      new Blob(
        [
          JSON.stringify({
            title: currentDeal.translations.en.title,
            description: currentDeal.translations.en.description,
            readyText: currentDeal.translations.en.readyText,
            language: 'en',
          }),
        ],
        { type: 'application/json' }
      )
    );

    formData.append(
      'frenchTranslation',
      new Blob(
        [
          JSON.stringify({
            title: currentDeal.translations.fr.title,
            description: currentDeal.translations.fr.description,
            readyText: currentDeal.translations.fr.readyText,
            language: 'fr',
          }),
        ],
        { type: 'application/json' }
      )
    );

    formData.append(
      'italianTranslation',
      new Blob(
        [
          JSON.stringify({
            title: currentDeal.translations.it.title,
            description: currentDeal.translations.it.description,
            readyText: currentDeal.translations.it.readyText,
            language: 'it',
          }),
        ],
        { type: 'application/json' }
      )
    );

    formData.append(
      'germanTranslation',
      new Blob(
        [
          JSON.stringify({
            title: currentDeal.translations.de.title,
            description: currentDeal.translations.de.description,
            readyText: currentDeal.translations.de.readyText,
            language: 'de',
          }),
        ],
        { type: 'application/json' }
      )
    );

    if (imageFile) {
      formData.append('imageFile', imageFile);
    }

    if (secondImageFile) {
      formData.append('secondImageFile', secondImageFile);
    }

    try {
      if (currentDeal.id) {
        await axios.put(`${apiUrl}/api/deals/${currentDeal.id}`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        });
      } else {
        await axios.post(`${apiUrl}/api/deals/user/${userEmail}`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        });
      }
      fetchDeals();
      handleClose();
    } catch (error) {
      console.error('Error saving deal', error);
    }
  };

  return (
    <Container style={{ marginTop: '50px', marginBottom: '50px', display: 'flex', justifyContent: 'center' }}>
      <Paper elevation={0} style={{ padding: '20px', width: '100%', maxWidth: '1400px',borderRadius:'30' }}>
        <Button variant="outlined" color="primary" onClick={() => handleOpen()} style={{ marginBottom: '20px' }}>
          Add Deal
        </Button>

        {/* Filtres de recherche côte à côte */}
        <Grid container spacing={2} style={{ marginBottom: '20px' }}>
          <Grid item xs={4}>
            <TextField
              label="Filter by Title"
              variant="outlined"
              fullWidth
              value={filterTitle}
              onChange={(e) => setFilterTitle(e.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Filter by Description"
              variant="outlined"
              fullWidth
              value={filterDescription}
              onChange={(e) => setFilterDescription(e.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Filter by Link"
              variant="outlined"
              fullWidth
              value={filterLink}
              onChange={(e) => setFilterLink(e.target.value)}
            />
          </Grid>
        </Grid>

        {loading ? (
          <CircularProgress />
        ) : (
          <TableContainer component={Paper} sx={{ width: '100%', overflowX: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow style={{ backgroundColor: '#2196f3' }}>
                  <TableCell style={{ fontWeight: 'bold', fontSize: '16px', padding: '10px', color: 'white' }}>
                    <TableSortLabel
                      active={orderBy === 'title'}
                      direction={orderBy === 'title' ? orderDirection : 'asc'}
                      onClick={() => handleSortRequest('title')}
                    >
                      Title
                    </TableSortLabel>
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold', fontSize: '16px', padding: '10px', color: 'white' }}>
                    <TableSortLabel
                      active={orderBy === 'description'}
                      direction={orderBy === 'description' ? orderDirection : 'asc'}
                      onClick={() => handleSortRequest('description')}
                    >
                      Description
                    </TableSortLabel>
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold', fontSize: '16px', padding: '10px', color: 'white' }}>
                    <TableSortLabel
                      active={orderBy === 'readyText'}
                      direction={orderBy === 'readyText' ? orderDirection : 'asc'}
                      onClick={() => handleSortRequest('readyText')}
                    >
                      Ready Text
                    </TableSortLabel>
                  </TableCell>
                  {/* Nouvelle colonne pour afficher le lien */}
                  <TableCell style={{ fontWeight: 'bold', fontSize: '16px', padding: '10px', color: 'white' }}>
                    <TableSortLabel
                      active={orderBy === 'link'}
                      direction={orderBy === 'link' ? orderDirection : 'asc'}
                      onClick={() => handleSortRequest('link')}
                    >
                      Link
                    </TableSortLabel>
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold', fontSize: '16px', padding: '10px', color: 'white' }}>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedDeals()
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((deal) => {
                    const englishTranslation = deal.translations.find((t) => t.language === 'en') || {};
                    return (
                      <TableRow key={deal.id}>
                        <TableCell>{englishTranslation.title}</TableCell>
                        <TableCell>{englishTranslation.description}</TableCell>
                        <TableCell>{englishTranslation.readyText}</TableCell>
                        {/* Affichage du lien */}
                        <TableCell>
                          <a href={deal.link} target="_blank" rel="noopener noreferrer">
                            {deal.link}
                          </a>
                        </TableCell>
                        <TableCell>
                          <IconButton onClick={() => handleOpen(deal)}>
                            <EditIcon />
                          </IconButton>
                          <IconButton onClick={() => handleDelete(deal.id)}>
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={deals.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              ActionsComponent={CustomPaginationActions}
            />
          </TableContainer>
        )}
        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>

        <Dialog
          open={open}
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              handleClose();
            }
          }}
        >
          <DialogTitle>{currentDeal.id ? 'Edit Deal' : 'Add Deal'}</DialogTitle>
          <DialogContent>
            <Tabs
              value={currentLanguage}
              onChange={handleLanguageChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
            >
              <Tab label="English" value="en" />
              <Tab label="Français" value="fr" />
              <Tab label="Deutsch" value="de" />
              <Tab label="Italiano" value="it" />
            </Tabs>
            <TextField
              margin="dense"
              label="Title"
              type="text"
              fullWidth
              name="title"
              value={currentDeal.translations[currentLanguage].title}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              label="Description"
              type="text"
              fullWidth
              name="description"
              value={currentDeal.translations[currentLanguage].description}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              label="Ready Text"
              type="text"
              fullWidth
              name="readyText"
              value={currentDeal.translations[currentLanguage].readyText}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              label="Link"
              type="text"
              fullWidth
              name="link"
              value={currentDeal.link}
              onChange={(e) => setCurrentDeal({ ...currentDeal, link: e.target.value })}
            />
            <Button
              variant="outlined"
              component="label"
              fullWidth
              style={{ marginTop: '10px', marginBottom: '10px' }}
            >
              Upload Primary Image
              <input type="file" hidden onChange={handleFileChange} />
            </Button>
            {imageFile && <p>Selected Image: {imageFile.name}</p>}
            <Button
              variant="outlined"
              component="label"
              fullWidth
              style={{ marginBottom: '10px' }}
            >
              Upload Secondary Image
              <input type="file" hidden onChange={handleSecondFileChange} />
            </Button>
            {secondImageFile && <p>Selected Secondary Image: {secondImageFile.name}</p>}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSubmit} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </Container>
  );
};

export default Deals;
