import React, { useState, useEffect, useCallback } from 'react';
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
  CircularProgress,
  Paper,
  Snackbar,
  Alert,
  TablePagination,
  TableSortLabel,
  Grid,
  TextField // Make sure to import this properly
} from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import axios from 'axios';
import { useAuth } from './auth';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const defaultUserIcon = 'https://cdn-icons-png.flaticon.com/512/847/847969.png';

const CustomPaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div style={{ flexShrink: 0, marginLeft: '2.5em' }}>
      <Button onClick={handleFirstPageButtonClick} disabled={page === 0}>
        First
      </Button>
      <Button onClick={handleBackButtonClick} disabled={page === 0}>
        Back
      </Button>
      <Button onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1}>
        Next
      </Button>
      <Button onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1}>
        Last
      </Button>
    </div>
  );
};

const Users = () => {
  const { token } = useAuth();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [orderBy, setOrderBy] = useState('');
  const [orderDirection, setOrderDirection] = useState('asc');
  const [filter, setFilter] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [startCreateDate, setStartCreateDate] = useState('');
  const [endCreateDate, setEndCreateDate] = useState('');
  const apiUrl = process.env.REACT_APP_BASE_USER_SERVER;
  const navigate = useNavigate();

  const fetchUsers = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/api/users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users', error);
    } finally {
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${apiUrl}/api/users/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSnackbarMessage('User deleted successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      fetchUsers();
    } catch (error) {
      setSnackbarMessage('Error deleting user');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      console.error('Error deleting user', error);
    }
  };

  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && orderDirection === 'asc';
    setOrderDirection(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedUsers = () => {
    const filteredUsers = users.filter((user) => {
      const name = user.name ? user.name.toLowerCase() : '';
      const email = user.email ? user.email.toLowerCase() : '';
      const city = user.city ? user.city.toLowerCase() : '';
      const country = user.country ? user.country.toLowerCase() : '';
      const userDateOfBirth = user.dateOfBirth ? moment(user.dateOfBirth) : null;
      const userCreateDate = user.createDate ? moment(user.createDate) : null;

      const isDateInRange =
        (!startDate || (userDateOfBirth && userDateOfBirth.isSameOrAfter(moment(startDate)))) &&
        (!endDate || (userDateOfBirth && userDateOfBirth.isSameOrBefore(moment(endDate))));

      const isCreateDateInRange =
        (!startCreateDate || (userCreateDate && userCreateDate.isSameOrAfter(moment(startCreateDate)))) &&
        (!endCreateDate || (userCreateDate && userCreateDate.isSameOrBefore(moment(endCreateDate))));

      return (
        (name.includes(filter.toLowerCase()) ||
          email.includes(filter.toLowerCase()) ||
          city.includes(filter.toLowerCase()) ||
          country.includes(filter.toLowerCase())) &&
        isDateInRange &&
        isCreateDateInRange
      );
    });

    return [...filteredUsers].sort((a, b) => {
      if (orderBy === 'name') {
        return (a.name < b.name ? -1 : 1) * (orderDirection === 'asc' ? 1 : -1);
      }
      if (orderBy === 'email') {
        return (a.email < b.email ? -1 : 1) * (orderDirection === 'asc' ? 1 : -1);
      }
      if (orderBy === 'dateOfBirth') {
        return (new Date(a.dateOfBirth) - new Date(b.dateOfBirth)) * (orderDirection === 'asc' ? 1 : -1);
      }
      if (orderBy === 'createDate') {
        return (new Date(a.createDate) - new Date(b.createDate)) * (orderDirection === 'asc' ? 1 : -1);
      }
      return 0;
    });
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleRowClick = (userId) => {
    navigate(`/user-details/${userId}`);
  };

  return (
    <Container style={{ marginTop: '50px', marginBottom: '50px', display: 'flex', justifyContent: 'center' }}>
      <Paper elevation={0} style={{ padding: '20px', width: '100%', maxWidth: '1400px', borderRadius: '30' }}>
        <Button variant="outlined" color="primary" style={{ marginBottom: '20px' }} disabled>
          Add User
        </Button>

        <TextField
          label="Search"
          variant="outlined"
          fullWidth
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          style={{ marginBottom: '20px' }}
        />

        <Grid container spacing={2} style={{ marginBottom: '20px' }}>
          <Grid item xs={6}>
            <TextField
              label="Start Date of Birth"
              type="date"
              fullWidth
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="End Date of Birth"
              type="date"
              fullWidth
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginBottom: '20px' }}>
          <Grid item xs={6}>
            <TextField
              label="Start Creation Date"
              type="date"
              fullWidth
              value={startCreateDate}
              onChange={(e) => setStartCreateDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="End Creation Date"
              type="date"
              fullWidth
              value={endCreateDate}
              onChange={(e) => setEndCreateDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>

        {loading ? (
          <CircularProgress />
        ) : (
          <TableContainer component={Paper} sx={{ width: '100%', overflowX: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow style={{ backgroundColor: '#2196f3' }}>
                  <TableCell style={{ fontWeight: 'bold', fontSize: '16px', padding: '10px', color: 'white' }}>
                    Picture
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold', fontSize: '16px', padding: '10px', color: 'white' }}>
                    <TableSortLabel
                      active={orderBy === 'name'}
                      direction={orderBy === 'name' ? orderDirection : 'asc'}
                      onClick={() => handleSortRequest('name')}
                    >
                      Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold', fontSize: '16px', padding: '10px', color: 'white' }}>
                    Last Name
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold', fontSize: '16px', padding: '10px', color: 'white' }}>
                    <TableSortLabel
                      active={orderBy === 'email'}
                      direction={orderBy === 'email' ? orderDirection : 'asc'}
                      onClick={() => handleSortRequest('email')}
                    >
                      Email
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: 'bold',
                      fontSize: '16px',
                      padding: '10px',
                      color: orderBy === 'dateOfBirth' ? 'red' : 'white',
                    }}
                  >
                    <TableSortLabel
                      active={orderBy === 'dateOfBirth'}
                      direction={orderBy === 'dateOfBirth' ? orderDirection : 'asc'}
                      onClick={() => handleSortRequest('dateOfBirth')}
                    >
                      Date of Birth
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: 'bold',
                      fontSize: '16px',
                      padding: '10px',
                      color: orderBy === 'createDate' ? 'red' : 'white',
                    }}
                  >
                    <TableSortLabel
                      active={orderBy === 'createDate'}
                      direction={orderBy === 'createDate' ? orderDirection : 'asc'}
                      onClick={() => handleSortRequest('createDate')}
                    >
                      Creation Date
                    </TableSortLabel>
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold', fontSize: '16px', padding: '10px', color: 'white' }}>
                    City
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold', fontSize: '16px', padding: '10px', color: 'white' }}>
                    Country
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold', fontSize: '16px', padding: '10px', color: 'white' }}>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedUsers()
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((user) => {
                    const profilePic = user.profilePicture
                      ? `${apiUrl}/uploads/${user.profilePicture}`
                      : defaultUserIcon;

                    return (
                      <TableRow key={user.id} onClick={() => handleRowClick(user.id)} style={{ cursor: 'pointer' }}>
                        <TableCell>
                          <img
                            src={profilePic}
                            alt={user.name}
                            style={{ width: '50px', height: '50px', borderRadius: '50%' }}
                          />
                        </TableCell>
                        <TableCell>{user.name}</TableCell>
                        <TableCell>{user.lastName}</TableCell>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>{user.dateOfBirth}</TableCell>
                        <TableCell>{user.createDate}</TableCell>
                        <TableCell>{user.city}</TableCell>
                        <TableCell>{user.country}</TableCell>
                        <TableCell>
                          <IconButton onClick={(e) => { e.stopPropagation(); handleDelete(user.id); }}>
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={users.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              ActionsComponent={CustomPaginationActions}
            />
          </TableContainer>
        )}
        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Paper>
    </Container>
  );
};

export default Users;
