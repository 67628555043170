import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { CircularProgress, Alert, Card, CardContent, Grid, Typography, Avatar, Paper, Button, TextField } from '@mui/material';
import moment from 'moment';
import { useAuth } from './auth';
import UserLocationMap from './UserLocationMap';
import UserEngagementCard from './UserEngagementCard';

const defaultUserIcon = 'https://cdn-icons-png.flaticon.com/512/847/847969.png';

const UserDetails = () => {
  const { token } = useAuth();
  const { userId } = useParams();
  const navigate = useNavigate(); // For navigation (back or to dashboard)
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false); // Toggle edit mode
  const [formData, setFormData] = useState({}); // Store form data
  const apiUrl = process.env.REACT_APP_BASE_USER_SERVER;

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/users/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUser(response.data);
        setFormData(response.data); // Initialize form data
      } catch (err) {
        setError('User not found');
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, [userId, token, apiUrl]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    try {
      await axios.put(`${apiUrl}/api/users/admin/${userId}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUser(formData); // Update the state with the modified data
      setIsEditing(false); // Exit edit mode
    } catch (err) {
      setError('Error saving user data');
    }
  };

  const formatDate = (date) => {
    return date ? moment(date).format('DD/MM/YYYY') : 'N/A';
  };

  const formatBalance = (balance) => {
    return balance !== undefined
      ? balance.toLocaleString('fr-FR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
      : 'N/A';
  };

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  const userLocation = user?.locations?.length > 0 ? user.locations[0] : null;

  const handleQuit = () => {
    if (isEditing) {
      setIsEditing(false); // Exit edit mode without saving
    } else {
      navigate(-1); // Go back to the previous page
    }
  };

  return (
    <div style={{ padding: '70px' }}>
      <Paper elevation={0} style={{ padding: '20px', borderRadius: '30' }}>
        <Grid container spacing={2} justifyContent="center">
          {/* Profile picture + Account info */}
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent style={{ textAlign: 'center' }}>
                <Avatar
                  alt={user?.name}
                  src={user?.profilePicture ? `${apiUrl}/uploads/${user.profilePicture}` : defaultUserIcon}
                  sx={{ width: 100, height: 100, margin: '0 auto' }}
                />
                {isEditing ? (
                  <>
                    <TextField
                      name="name"
                      label="First Name"
                      value={formData.name}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                    />
                    <TextField
                      name="lastName"
                      label="Last Name"
                      value={formData.lastName}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                    />
                  </>
                ) : (
                  <>
                    <Typography variant="h5" component="div" gutterBottom>
                      {user?.name} {user?.lastName}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {user?.email}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {user?.city}, {user?.country}
                    </Typography>
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Account Information
                </Typography>
                <Grid container spacing={2}>
                  {isEditing ? (
                    <>
                      <Grid item xs={12} md={6}>
                        <TextField
                          name="email"
                          label="Email"
                          value={formData.email}
                          onChange={handleChange}
                          fullWidth
                          margin="normal"
                          type="email"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          name="phone"
                          label="Phone"
                          value={formData.phone || ''}
                          onChange={handleChange}
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          name="dateOfBirth"
                          label="Date of Birth"
                          type="date"
                          value={formData.dateOfBirth || ''}
                          onChange={handleChange}
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          name="balance"
                          label="Balance"
                          type="number"
                          inputProps={{ step: '0.01', min: '0' }}
                          value={formData.balance || ''}
                          onChange={handleChange}
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          name="iban"
                          label="IBAN"
                          value={formData.iban || ''}
                          onChange={handleChange}
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          name="paypalEmail"
                          label="PayPal Email"
                          value={formData.paypalEmail || ''}
                          onChange={handleChange}
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          name="city"
                          label="City"
                          value={formData.city || ''}
                          onChange={handleChange}
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          name="country"
                          label="Country"
                          value={formData.country || ''}
                          onChange={handleChange}
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          name="zipCode"
                          label="Zip Code"
                          value={formData.zipCode || ''}
                          onChange={handleChange}
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          name="address"
                          label="Address"
                          value={formData.address || ''}
                          onChange={handleChange}
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={12} md={6}>
                        <Typography variant="body1">
                          <strong>Email:</strong> {user?.email || 'N/A'}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="body1">
                          <strong>Phone:</strong> {user?.phone || 'N/A'}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="body1">
                          <strong>Date of Birth:</strong> {formatDate(user?.dateOfBirth)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="body1">
                          <strong>Balance:</strong> {formatBalance(user?.balance)} CHF
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="body1">
                          <strong>IBAN:</strong> {user?.iban || 'N/A'}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="body1">
                          <strong>PayPal Email:</strong> {user?.paypalEmail || 'N/A'}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="body1">
                          <strong>City:</strong> {user?.city || 'N/A'}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="body1">
                          <strong>Country:</strong> {user?.country || 'N/A'}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="body1">
                          <strong>Zip Code:</strong> {user?.zipCode || 'N/A'}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="body1">
                          <strong>Address:</strong> {user?.address || 'N/A'}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="body1">
                          <strong>Created At:</strong> {formatDate(user?.createDate)}
                        </Typography>
                      </Grid>
                    </>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/* User engagement and location */}
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Engagement
                </Typography>
                <UserEngagementCard userId={userId} />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Location
                </Typography>
                <UserLocationMap user={user} userLocation={userLocation} apiUrl={apiUrl} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {isEditing ? (
          <>
            <Button variant="contained" color="primary" onClick={handleSave} style={{ marginTop: '20px' }}>
              Save
            </Button>
            <Button variant="outlined" color="secondary" onClick={handleQuit} style={{ marginLeft: '10px', marginTop: '20px' }}>
              Cancel
            </Button>
          </>
        ) : (
          <Button variant="outlined" color="primary" onClick={() => setIsEditing(true)} style={{ marginTop: '20px' }}>
            Edit
          </Button>
        )}
        <Button variant="outlined" color="secondary" onClick={handleQuit} style={{ marginLeft: '10px', marginTop: '20px' }}>
          Quit
        </Button>
      </Paper>
    </div>
  );
};

export default UserDetails;
