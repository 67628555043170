import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {
    Container,
    Typography,
    CircularProgress,
    Paper,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
} from '@mui/material';
import { useAuth } from './auth';
import {
    BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, PieChart, Pie, Cell, Legend, ResponsiveContainer
} from 'recharts';
import { useReactToPrint } from 'react-to-print';

const apiUrl = process.env.REACT_APP_BASE_USER_SERVER;
const COLORS = ['#0088FE', '#FF69B4', '#FFBB28', '#FF8042', '#AF19FF', '#00C49F', '#FF8042', '#FF6347', '#4682B4']; // Add more colors for multiple responses

const StatisticsReport = () => {
    const { token } = useAuth();
    const { id } = useParams();
    const [report, setReport] = useState(null);
    const [loading, setLoading] = useState(true);
    const componentRef = useRef();

    useEffect(() => {
        const fetchReport = async () => {
            try {
                const response = await axios.get(`${apiUrl}/api/statistics/question/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setReport(response.data);
            } catch (error) {
                console.error('Error fetching report', error);
            } finally {
                setLoading(false);
            }
        };

        fetchReport();
    }, [id, token]);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: `Statistics_Report_Question_${id}`,
        pageStyle: `
            @page {
                size: A4;
                margin: 20mm;
            }
            body {
                margin: 0;
                -webkit-print-color-adjust: exact;
            }
            @page {
                margin: 0;
                @bottom-center {
                    content: "Powered by NOVA METRICS";
                    font-size: 12px;
                    color: #333;
                    margin-bottom: 10mm;
                }
            }
            @media print {
                .print-footer {
                    display: block;
                    text-align: center;
                    position: fixed;
                    bottom: 10px;
                    width: 100%;
                    font-size: 12px;
                    color: #333;
                }
            }
        `,
    });

    if (loading) {
        return <CircularProgress />;
    }

    if (!report) {
        return <Typography>No data available</Typography>;
    }

    // Data for the gender distribution pie chart
    const genderData = [
        { name: 'Male', value: report.malePercentage || 0 },
        { name: 'Female', value: report.femalePercentage || 0 },
    ];

    // Custom label function to draw arrows pointing to the pie segments
    const renderCustomLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const RADIAN = Math.PI / 180;
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        const xLine = cx + (outerRadius + 10) * Math.cos(-midAngle * RADIAN);
        const yLine = cy + (outerRadius + 10) * Math.sin(-midAngle * RADIAN);
        const xAnchor = xLine + (midAngle > 90 && midAngle < 270 ? -1 : 1) * 20;

        const genderName = genderData[index]?.name || 'Unknown';
        const percentage = (percent * 100).toFixed(0) || '0';

        return (
            <g>
                <text
                    x={xAnchor}
                    y={yLine}
                    fill="black"
                    textAnchor={xLine > cx ? 'start' : 'end'}
                    dominantBaseline="central"
                >
                    {`${genderName}: ${percentage}%`}
                </text>
                <line x1={x} y1={y} x2={xLine} y2={yLine} stroke="black" />
            </g>
        );
    };

    // Transform data for charts
    const ageData = Object.entries(report.ageDistribution).map(([ageGroup, count]) => ({
        ageGroup,
        count,
    }));

    const locationData = Object.entries(report.locationStats).map(([locationKey, stats]) => ({
        location: locationKey,
        totalUsers: stats.totalUsers,
        maleUsers: stats.maleUsers,
        femaleUsers: stats.femaleUsers,
    }));

    // Function to generate chart data for each answer
    const getChartDataForAnswer = (answer) => {
        return Object.entries(answer.locationPercentages || {}).map(([location, percentage]) => ({
            location,
            percentage,
        }));
    };

    // Generate data for bar charts based on answers, with validation
    const answerData = report.answerStats.map((answer) => ({
        answerText: answer.answerText,
        percentage: answer.percentage,
    }));

    // Filter out zero percentage entries for the pie chart
    const validAnswerData = answerData.filter((answer) => answer.percentage > 0);

    // Function to handle wrapping long names in legends
    const formatLegendText = (value) => {
        const maxLength = 15; // Set a max length for each word
        if (value.length > maxLength) {
            return value.match(new RegExp(`.{1,${maxLength}}`, 'g')).join('\n'); // Split the long string into multiple lines
        }
        return value;
    };

    return (
        <Container>
            <Button variant="contained" color="primary" onClick={handlePrint} style={{ margin: '20px 0' }}>
                Download Report as PDF
            </Button>

            <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }} ref={componentRef}>
                <Typography variant="h5" align="center" gutterBottom>
                    Sample Report for Question {id}

                </Typography>
                
                <Typography align="center" gutterBottom>
                    Generated At: {new Date().toLocaleString()}
                </Typography>

                {/* Gender Distribution Pie Chart and Text Info Side by Side */}
                <Box my={2} display="flex" justifyContent="center" alignItems="center">
                    <Box width="50%">
                        <ResponsiveContainer width="100%" height={300}>
                            <PieChart>
                                <Pie
                                    data={genderData}
                                    dataKey="value"
                                    nameKey="name"
                                    cx="50%"
                                    cy="50%"
                                    innerRadius={70}
                                    outerRadius={90}
                                    fill="#8884d8"
                                    labelLine={false}
                                    label={renderCustomLabel}
                                    paddingAngle={10}
                                >
                                    {genderData.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                                <Tooltip />
                                <Legend formatter={formatLegendText} verticalAlign="bottom" height={36} />
                            </PieChart>
                        </ResponsiveContainer>
                    </Box>
                    <Box width="50%" textAlign="left">
                        <Typography variant="h6">Total Respondents: {report.totalRespondents}</Typography>
                        <Typography variant="h6">Female Percentage: {report.femalePercentage}%</Typography>
                        <Typography variant="h6">Male Percentage: {report.malePercentage}%</Typography>
                    </Box>
                </Box>

                {/* Age Distribution */}
                <Box my={2} display="flex" justifyContent="center">
                    <ResponsiveContainer width="90%" height={400}> {/* Increase width */}
                        <BarChart data={ageData} layout="vertical" margin={{ left: 30, right: 30 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis type="number" />
                            <YAxis dataKey="ageGroup" type="category" tick={{ fontSize: 12 }} width={200} /> {/* Reduce font size */}
                            <Tooltip />
                            <Legend formatter={formatLegendText} verticalAlign="bottom" height={36} />
                            <Bar dataKey="count" fill="#8884d8" barSize={20} />
                        </BarChart>
                    </ResponsiveContainer>
                </Box>

                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Age Group</TableCell>
                                <TableCell>Count</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {ageData.map((entry) => (
                                <TableRow key={entry.ageGroup}>
                                    <TableCell>{entry.ageGroup}</TableCell>
                                    <TableCell>{entry.count}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* Location Statistics */}
                <Box my={2} display="flex" justifyContent="center">
                    <ResponsiveContainer width="100%" height={1600}> {/* Increase width and height */}
                        <BarChart 
                        data={locationData} 
                        layout="vertical" 
                        margin={{right: 30 }}
                        barCategoryGap="60%"
                        barGap={40}>
                            <CartesianGrid strokeDasharray="5 5" />
                            <XAxis type="number" />
                            <YAxis dataKey="location" type="category" tick={{ fontSize: 12 }} width={250} /> {/* Increase width and reduce font size */}
                            <Tooltip />
                            <Legend formatter={formatLegendText} verticalAlign="bottom"/>
                            <Bar dataKey="totalUsers" fill="#8884d8" barSize={20} />
                            <Bar dataKey="maleUsers" fill="#82ca9d" barSize={20} />
                            <Bar dataKey="femaleUsers" fill="#ffc658" barSize={20} />
                        </BarChart>
                    </ResponsiveContainer>
                </Box>

                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Location</TableCell>
                                <TableCell>Total Users</TableCell>
                                <TableCell>Male Users</TableCell>
                                <TableCell>Female Users</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {locationData.map((entry) => (
                                <TableRow key={entry.location}>
                                    <TableCell>{entry.location}</TableCell>
                                    <TableCell>{entry.totalUsers}</TableCell>
                                    <TableCell>{entry.maleUsers}</TableCell>
                                    <TableCell>{entry.femaleUsers}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* Answer Statistics Pie Chart */}
                <Box my={2} display="flex" justifyContent="center">
                    <ResponsiveContainer width="90%" height={300}> {/* Increase width */}
                        <PieChart>
                            <Pie
                                data={validAnswerData}  // Use valid data only
                                dataKey="percentage"
                                nameKey="answerText"
                                cx="50%"
                                cy="50%"
                                innerRadius={70}
                                outerRadius={90}
                                fill="#8884d8"
                                labelLine={false}
                                label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
                                paddingAngle={5}
                            >
                                {validAnswerData.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                            <Tooltip />
                            <Legend formatter={formatLegendText} verticalAlign="bottom" height={36} />
                        </PieChart>
                    </ResponsiveContainer>
                </Box>

                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Answer Text</TableCell>
                                <TableCell>Percentage</TableCell>
                                <TableCell>Total Users</TableCell>
                                <TableCell>Male Users</TableCell>
                                <TableCell>Female Users</TableCell>
                                <TableCell>Location Percentages</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {report.answerStats.map((answer, index) => (
                                <TableRow key={index}>
                                    <TableCell>{answer.answerText}</TableCell>
                                    <TableCell>{answer.percentage}%</TableCell>
                                    <TableCell>{answer.totalUsers}</TableCell>
                                    <TableCell>{answer.maleUsers}</TableCell>
                                    <TableCell>{answer.femaleUsers}</TableCell>
                                    <TableCell>
                                        {Object.entries(answer.locationPercentages).map(([locationKey, percentage]) => (
                                            <div key={locationKey}>
                                                {locationKey}: {percentage}%
                                            </div>
                                        ))}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* Bar Charts for Each Answer Below the Table */}
                {report.answerStats.map((answer, index) => (
                    <Box key={index} my={4} display="flex" justifyContent="center">
                        <Typography variant="h6" align="center">
                            {answer.answerText}
                        </Typography>
                        <ResponsiveContainer width="90%" height={800}> {/* Increase width */}
                            <BarChart data={getChartDataForAnswer(answer)} layout="vertical">
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis type="number" />
                                <YAxis dataKey="location" type="category" tick={{ fontSize: 12 }} width={200} /> {/* Increase width and reduce font size */}
                                <Tooltip />
                                <Legend formatter={formatLegendText} verticalAlign="bottom" height={36} />
                                <Bar dataKey="percentage" fill={COLORS[index % COLORS.length]} barSize={20} />
                            </BarChart>
                        </ResponsiveContainer>
                    </Box>
                ))}

            </Paper>

            <style>
                {`
                @media print {
                    .print-footer {
                        display: block;
                        text-align: center;
                        position: fixed;
                        bottom: 10px;
                        width: 100%;
                        font-size: 12px;
                        color: #333;
                    }
                }
                `}
            </style>
        </Container>
    );
};

export default StatisticsReport;
