import React, { useState } from 'react';
import { Container, TextField, Button, Typography, CircularProgress } from '@mui/material';
import { useAuth } from './auth';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { login } = useAuth();
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_BASE_USER_SERVER;

  const handleLogin = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await axios.post(`${apiUrl}/api/authenticate`, { email, password });
      if (response.status === 200) {
        login(response.data.jwt);
        navigate('/');
      } else {
        setError('Login failed: ' + response.statusText);
      }
    } catch (err) {
      setError('Invalid credentials');
      setLoading(false);
    }
  };

  return (
    <Container  maxWidth="sm">
      <Typography variant="h4" component="h1" gutterBottom>
        Login
      </Typography>
      {error && <Typography color="error">{error}</Typography>}
      <TextField
        label="Email"
        variant="outlined"
        fullWidth
        margin="normal"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <TextField
        label="Password"
        type="password"
        variant="outlined"
        fullWidth
        margin="normal"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleLogin}
        disabled={loading}
      >
        {loading ? <CircularProgress size={24} /> : 'Login'}
      </Button>
      <Button color="primary" onClick={() => navigate('/reset-password')}>
        Forgot Password?
      </Button>
    </Container>
  );
};

export default Login;
