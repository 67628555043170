import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from './auth';
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  Toolbar,
  CssBaseline,
  Typography,
  Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import logo from './assets/Icon-nova.png';

const drawerWidth = 280; // Adjust the width of the sidebar

const Navbar = () => {
  const { token, logout } = useAuth();
  const { t } = useTranslation();
  const [drawerOpen, setDrawerOpen] = useState(true); // Sidebar stays open by default

  const drawerList = (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        backgroundColor: '#1976d2', // Blue background for the sidebar
        color: 'white', // White text for visibility
      }}
    >
      {/* Logo at the top */}
      <div style={{ padding: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <img src={logo} width={100} height={100} alt="Logo" />
      </div>
      <Divider style={{ backgroundColor: 'white' }} />
      <List style={{ flexGrow: 1 }}>
        <ListItem button component={Link} to="/">
          <ListItemText primary={t('home')} style={{ color: 'white' }} />
        </ListItem>
        <ListItem button component={Link} to="/questions">
          <ListItemText primary={t('questions')} style={{ color: 'white' }} />
        </ListItem>
        <ListItem button component={Link} to="/users">
          <ListItemText primary={t('users')} style={{ color: 'white' }} />
        </ListItem>
        <ListItem button component={Link} to="/payouts">
          <ListItemText primary={t('payout operations')} style={{ color: 'white' }} />
        </ListItem>
        <ListItem button component={Link} to="/deals">
          <ListItemText primary={t('deals')} style={{ color: 'white' }} />
        </ListItem>
        <ListItem button component={Link} to="/announcements">
          <ListItemText primary={t('announcements')} style={{ color: 'white' }} />
        </ListItem>
      </List>

      {/* Logout button at the bottom */}
      <Divider style={{ backgroundColor: 'white' }} />
      <ListItem button onClick={logout} style={{ marginBottom: '16px', color: 'white' }}>
        <ListItemText primary={t('logout')} style={{ color: 'white' }} />
      </ListItem>
    </div>
  );

  return (
    <>
      <CssBaseline />
      <Drawer
        variant="permanent"
        anchor="left"
        PaperProps={{ style: { width: drawerWidth, backgroundColor: '#1976d2' } }} // Blue color
      >
        {drawerList}
      </Drawer>

    </>
  );
};

export default Navbar;
