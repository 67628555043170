import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { CircularProgress, Typography, Grid } from '@mui/material';
import { useAuth } from './auth'; // Pour le token

const UserEngagementCard = ({ userId }) => {
  const { token } = useAuth();
  const apiUrl = process.env.REACT_APP_BASE_USER_SERVER;

  const [dailyEngagement, setDailyEngagement] = useState(null);
  const [weeklyEngagement, setWeeklyEngagement] = useState(null);
  const [monthlyEngagement, setMonthlyEngagement] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEngagement = async () => {
      try {
        const daily = await axios.get(`${apiUrl}/api/engagement/user/${userId}/daily`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const weekly = await axios.get(`${apiUrl}/api/engagement/user/${userId}/weekly`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const monthly = await axios.get(`${apiUrl}/api/engagement/user/${userId}/monthly`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setDailyEngagement(daily.data);
        setWeeklyEngagement(weekly.data);
        setMonthlyEngagement(monthly.data);
      } catch (err) {
        setError('Failed to fetch engagement data');
      } finally {
        setLoading(false);
      }
    };

    fetchEngagement();
  }, [userId, token, apiUrl]);

  const formatEngagement = (engagement) => {
    if (!engagement) return '0';
    const { totalVisibleQuestions, totalAnsweredQuestions, totalMissedQuestions } = engagement;

    // Calcul du pourcentage de réponses en fonction de answered / (answered + visible)
    const answeredPercentage = totalAnsweredQuestions + totalVisibleQuestions > 0
      ? ((totalAnsweredQuestions / (totalAnsweredQuestions + totalVisibleQuestions)) * 100).toFixed(2)
      : 0;

    return `Visible: ${totalVisibleQuestions}, Answered: ${totalAnsweredQuestions} (${answeredPercentage}%), Missed: ${totalMissedQuestions}`;
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body1">
          <strong>Daily:</strong> {formatEngagement(dailyEngagement)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          <strong>Weekly:</strong> {formatEngagement(weeklyEngagement)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          <strong>Monthly:</strong> {formatEngagement(monthlyEngagement)}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default UserEngagementCard;
