import React, { useState, useEffect, useCallback } from 'react';
import { Container, Typography, CircularProgress, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet';
import axios from 'axios';
import L from 'leaflet';
import { useAuth } from './auth'; // Assuming useAuth returns the token
import './Home.css'; // Import any necessary CSS for the map markers

// Example of user profile icon (fallback)
const defaultUserIcon = 'https://cdn-icons-png.flaticon.com/512/847/847969.png';

// Custom marker function with profile picture or default icon
const createCustomIcon = (user, apiUrl) => {
  const borderColor = user.gender === 'FEMALE' ? 'DeepPink' : 'blue'; // Pink for females, blue for males
  const profilePic = user.profilePicture 
    ? `${apiUrl}/uploads/${user.profilePicture}`  // Concatenate apiUrl with profilePicture
    : defaultUserIcon; // Use default icon if no profile picture

  const html = `
    <div class="custom-marker" style="border-color: ${borderColor}">
      <img src="${profilePic}" alt="profile" class="profile-pic" />
    </div>
  `;

  return new L.DivIcon({
    html,
    className: 'custom-div-icon',
    iconSize: [40, 40],
    iconAnchor: [20, 40],
    popupAnchor: [0, -40],
  });
};

// Component to track map events and update the zoom and center state
const MapEventsHandler = ({ setCenter, setZoom }) => {
  useMapEvents({
    zoomend: (event) => {
      setZoom(event.target.getZoom());
    },
    moveend: (event) => {
      setCenter(event.target.getCenter());
    },
  });
  return null;
};

const Home = () => {
  const { t } = useTranslation();
  const apiUrl = process.env.REACT_APP_BASE_USER_SERVER;
  const { token } = useAuth(); // Assuming useAuth provides token

  // State for user locations and loading
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);

  // State for map's zoom and center position
  const [zoom, setZoom] = useState(2); // Default zoom level
  const [center, setCenter] = useState([48.8584, 2.2945]); // Default center (Paris)

  // Fetch users and their locations
  const fetchUsersWithLocations = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/api/users/locations`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUsers(response.data); // Assuming response returns the list of users with locations
    } catch (error) {
      console.error('Error fetching user locations', error);
    } finally {
      setLoading(false);
    }
  }, [token, apiUrl]);

  // Call fetchUsersWithLocations when the component mounts
  useEffect(() => {
    fetchUsersWithLocations();
  }, [fetchUsersWithLocations]);

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        {t('welcome')}
      </Typography>

      {/* Refresh Button */}
      <Button 
        variant="contained" 
        color="primary" 
        onClick={fetchUsersWithLocations} 
        style={{ marginBottom: '20px' }}
      >
        Refresh
      </Button>
      
      {loading ? (
        <CircularProgress />
      ) : (
        <MapContainer
          center={center} // Use the center from state
          zoom={zoom} // Use the zoom from state
          style={{ height: '600px', width: '100%' }}
        >
          {/* Track map events to update zoom and center */}
          <MapEventsHandler setCenter={setCenter} setZoom={setZoom} />

          {/* Tile layer for the base map (OpenStreetMap) */}
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />

          {/* Display user markers */}
          {users.map((user) =>
            user.locations.map((location, index) => {
              const profilePic = user.profilePicture 
                ? `${apiUrl}/uploads/${user.profilePicture}`  // Concatenate apiUrl with profilePicture
                : defaultUserIcon;

              return (
                <Marker
                  key={`${user.userId}-${index}`} // Unique key for each marker
                  position={[location.latitude, location.longitude]}
                  icon={createCustomIcon(user, apiUrl)} // Pass apiUrl to construct full profile picture URL
                >
                  <Popup>
                    <div style={{ textAlign: 'center' }}>
                      <img
                        src={profilePic}
                        alt={`${user.name} ${user.lastName}`}
                        style={{ width: '80px', height: '80px', borderRadius: '50%' }}
                      />
                      <div>
                        <strong>{user.name} {user.lastName}</strong><br />
                        Email: {user.email}<br />
                        Latitude: {location.latitude}, Longitude: {location.longitude}<br />
                        At: {location.timestamp}
                      </div>
                    </div>
                  </Popup>
                </Marker>
              );
            })
          )}
        </MapContainer>
      )}
    </Container>
  );
};

export default Home;
