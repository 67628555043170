import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css'; // Import du CSS leaflet
import './UserLocationMap.css'; // Import du CSS pour les icônes

const defaultUserIcon = 'https://cdn-icons-png.flaticon.com/512/847/847969.png';

// Fonction pour créer une icône personnalisée
const createCustomIcon = (user, apiUrl) => {
  const borderColor = user.gender === 'FEMALE' ? 'DeepPink' : 'blue';
  const profilePic = user.profilePicture
    ? `${apiUrl}/uploads/${user.profilePicture}`
    : defaultUserIcon;

  const html = `
    <div class="custom-marker" style="border-color: ${borderColor}">
      <img src="${profilePic}" alt="profile" class="profile-pic" />
    </div>
  `;

  return new L.DivIcon({
    html,
    className: 'custom-div-icon',
    iconSize: [40, 40],
    iconAnchor: [20, 40],
    popupAnchor: [0, -40],
  });
};

const UserLocationMap = ({ user, userLocation, apiUrl }) => {
  if (!userLocation) {
    return <div>No location available</div>;
  }

  return (
    <div style={{ height: '400px', width: '100%' }}>
      <MapContainer
        center={[userLocation.latitude, userLocation.longitude]}
        zoom={13}
        style={{ height: '100%', width: '100%' }} // Taille de la carte
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker
          position={[userLocation.latitude, userLocation.longitude]}
          icon={createCustomIcon(user, apiUrl)}
        >
          <Popup>
            <strong>{user?.name} {user?.lastName}</strong><br />
            Latitude: {userLocation.latitude}, Longitude: {userLocation.longitude}
          </Popup>
        </Marker>
      </MapContainer>
    </div>
  );
};

export default UserLocationMap;
